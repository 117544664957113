import Vue from 'vue'
import { mapState } from 'vuex'
import { State, DeviceInfo } from '@/store/modules/app'

/** 全局混入的计算属性 */
export interface MxinComputedProperties {
	/** 屏幕宽度 */
	$G_window_width: DeviceInfo['width']
	/** 屏幕高度 */
	$G_window_height: DeviceInfo['height']
	/** 设备类型 */
	$G_device_type: DeviceInfo['type']
	/** dialog适配宽度 */
	$G_dialog_auto_width: string
}

declare module 'vue/types/vue' {
	interface Vue extends MxinComputedProperties {}
}

export default Vue.extend({
	computed: {
		...mapState<State>('app', {
			$G_window_width: (state) => state.device_info.width,
			$G_window_height: (state) => state.device_info.height,
			$G_device_type: (state) => state.device_info.type,
			$G_dialog_auto_width: (state) => {
				if (state.device_info.width >= 1200) return '40%'
				if (state.device_info.width >= 800) return '55%'
				else if (state.device_info.width >= 600) return '70%'
				else return '95%'
			},
		}),
	},
})
