import { storage } from 'llm-web-tool'

/** 设置全局local-storage前缀 */
storage.config({
	prefix: 'zmm-',
})

/** 管理员Token */
export const adminToken = {
	/**
	 * 设置token
	 * @param token token值
	 */
	set: (token = '') => {
		storage.local.set('admin-token', token)
	},
	/**
	 * 获取token
	 * @param drop 是否去除后清空
	 */
	get: (drop = false) => {
		let token = storage.local.get('admin-token')
		if (drop) storage.local.remove('admin-token')
		return token
	},
}
