import Vue from 'vue'
import * as Filters from './index'

declare module 'vue/types/vue' {
	interface Vue {
		$filters: typeof Filters
	}
}

export default {
	install(vue) {
		vue.prototype.$filters = Filters
		Object.keys(Filters).forEach((key) => {
			vue.filter(key, Filters[key])
		})
	},
}
