import { Route, NavigationGuardNext } from 'vue-router'
import router from '@/router'
import { cloneDeep } from 'lodash'
import store from '@/store'

/** 全局前置守卫 */
export const beforeEach = async (to: Route, from: Route, next: NavigationGuardNext) => {
	// 授权登录回调（通过URL中携带TOKEN进入时，直接设置TOKEN，并移除参数）
	// if (to.query.TOKEN) {
	// 	store.commit("admin/setToken", to.query.TOKEN)
	// 	return store.dispatch('admin/updateInfo').then(() => {
	// 		let replaceTo = cloneDeep(to);
	// 		delete replaceTo.query.TOKEN;
	// 		next(replaceTo)
	// 	}).catch(e => {
	// 		next();
	// 	})
	// }
	// 验证登录状态
	let default_routes = ['home', 'login']
	if (to.meta?.login !== false && !store.state.admin.info)
		return store
			.dispatch('admin/updateInfo', { show_message: false })
			.then(() => {
				if (store.state.admin.info?.id != 1) {
					let role_routes = default_routes.concat((store.state.admin.info?.role?.menus ?? []).map((res) => res.key))
					if (role_routes.indexOf(to?.name as any) < 0) return next({ name: 'home' })
				}
				next()
			})
			.catch(() => next({ name: 'login' }))
	else {
		if (store.state.admin.info?.id != 1) {
			let role_routes = default_routes.concat((store.state.admin.info?.role?.menus ?? []).map((res) => res.key))
			if (role_routes.indexOf(to?.name as any) < 0) return next({ name: 'home' })
		}
	}
	next()
}

/** 全局后守卫 */
export const afterEach = async (to: Route, from: Route) => {
	// 面包屑
	store.commit('app/setBreadCurmbList', to.meta && to.meta.breadcrumb ? to.meta.breadcrumb : [])

	// 页面标题
	if (to.meta && typeof to.meta.title == 'string') document.title = to.meta.title
}

/** 其他权限守卫... */
