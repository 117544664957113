













import Vue from 'vue'

// 加载icon js
const ICONFONT_URL = '//at.alicdn.com/t/font_3000712_9reuzfcwfd4.js'
const tag = document.createElement('script')
tag.src = ICONFONT_URL
document.body.prepend(tag)

export default Vue.extend({
	props: {
		/** 图标名 */
		name: String,
		/** 颜色 */
		color: String,
		/** 尺寸 */
		size: [String, Number],
		/** 是否是块级元素 */
		block: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		showSize() {
			if (!this.size) return this.size
			return isNaN(parseFloat(this.size.toString())) ? this.size : `${this.size}px`
		},
	},
})
